html,
body,
body>div {
    /* the react root */
    margin: 0;
    padding: 0;
    height: 50%;
}

h2 {
    margin: 0;
    font-size: 16px;
}

b {
    /* used for event dates/times */
    margin-right: 3px;
}

/* both side and calender div */
.demo-app {
    /* margin-top: '20%'; */
    display: flex;
    min-height: 100%;
    min-width: 90%;
    font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
    font-size: 20px;
    font-weight: bolder;
    border: 1px solid black;
    margin: 3%;
}

.demo-app-sidebar {
    width: 1350px;
    line-height: 1;
    background: #eaf9ff;
    border-right: 1px solid black;
}

/* style for eventlist side bar */
.demo-app-sidebar-section {
    padding: 1em;
}


.demo-app-main {
    padding: 0.5em;
    height: 15%;
    width: 30%;
}

.fc-day-today {
    background-color: orange !important;

}

.fc-day-sun {
    background-color: red !important;
    color: yellow;
}

.fc {
    background-color: lightsteelblue;
    border: 2px solid black;
}