/* .App {
  align-items: center;
  text-align: center;
} */



/* styles.css */

table {
  border: 2px solid #000;
  border-collapse: collapse;
  width: 100%;
}

th {
  border: 2px solid #000;
  padding: 8px;
}
td{
  border: 2px solid #000;
  padding: 8px;
  font-weight: 500;
}



.standardSelected{
  background-color: yellow !important;
}

.selected {
  background: #038da6 !important;
  color: white !important;
}

.Selected {
  background: #035e06 !important;
  color: white !important;
  
}

.selectSubject {
  background : #03946d !important;
  color: white !important;
}

.selectedTest{
background : #3661b494 !important;
color: white !important;
}

.highlight{
  background-color: black !important;
  color: white !important;
  font-weight: bold !important;
}


.App-header {
  background-color: #ffffff;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* Your styles.css file or inline styles object */
.myRowClassName {
  border: 1px solid black;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::-webkit-scrollbar {
  width: 12px;
  height: 15px;
}
::-webkit-scrollbar-thumb {
  background-color: #9e9e9e;
  border-radius: 50px;
}
::-webkit-scrollbar-track {
background-color: #ffffff;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #746f6f; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}